import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import SvgColor from 'src/components/svg-color';
// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{
    width: 1,
    height: 1
  }} />
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {

  const { t } = useLocales();

  const data = useMemo(() => [{
    subheader: t('Pages'),
    items: [
      {
        title: t("Settings & Masters"),
        icon: ICONS.ecommerce,
        path: paths.dashboard.general.settings.main,
        children: [
          // {
          //   title: t("company info"),
          //   path: paths.dashboard.general.settings.info
          // },
          {
            title: t("companies"),
            path: paths.dashboard.general.settings.companies
          },
          {
            title: t("cities"),
            path: paths.dashboard.general.settings.cities
          },
          {
            title: t("zones"),
            path: paths.dashboard.general.settings.zones
          },
          {
            title: t("services"),
            path: paths.dashboard.general.settings.services
          }
        ]
      },
      {
        title: t("yard management"),
        icon: ICONS.tour,
        path: paths.dashboard.general.yards.main,
        children: [
          {
            title: t("yard list"),
            path: paths.dashboard.general.yards.main
          },
        ]
      },
      {
        title: t("driver management"),
        icon: ICONS.booking,
        path: paths.dashboard.general.driver.main,
        children: [
          {
            title: t("driver list"),
            path: paths.dashboard.general.driver.main
          },
          {
            title: t("General Details"),
            path: paths.dashboard.general.driver.tabNavigator("generalDetails")
          },
          {
            title: t("disability insurance"),
            path: paths.dashboard.general.driver.tabNavigator("disabilityInsurance")
          },
          {
            title: t("experience"),
            path: paths.dashboard.general.driver.tabNavigator("driversExperience")
          },
          {
            title: t("license"),
            path: paths.dashboard.general.driver.tabNavigator("license")
          },
          {
            title: t("CVOR"),
            path: paths.dashboard.general.driver.tabNavigator("cvor")
          },
          {
            title: t("insurance details"),
            path: paths.dashboard.general.driver.tabNavigator("insuranceDetails")
          },
        ]
      },
      {
        title: t("client management"),
        icon: ICONS.blog,
        path: paths.dashboard.general.clients.main,
        children: [
          {
            title: t("client list"),
            path: paths.dashboard.general.clients.main
          },
          {
            title: t("client information"),
            path: paths.dashboard.general.clients.tabNavigator("clientDetail")
          },

          {
            title: t("contacts"),
            path: paths.dashboard.general.clients.tabNavigator("contactList")
          },

          {
            title: t("service locations"),
            path: paths.dashboard.general.clients.tabNavigator("serviceLocation")
          },
          {
            title: t("vendors"),
            path: paths.dashboard.general.clients.vendors
          },
        ]
      },
      {
        title: t("trucks management"),
        icon: ICONS.kanban,
        path: paths.dashboard.general.trucks.main,
        children: [
          {
            title: t("Trucks List"),
            path: paths.dashboard.general.trucks.main
          },
          {
            title: t("license plate list"),
            path: paths.dashboard.general.trucks.tabNavigator("license")
          },
          {
            title: t("maintainance list"),
            path: paths.dashboard.general.trucks.tabNavigator("maintainance")
          },
          {
            title: t("annual inspection list"),
            path: paths.dashboard.general.trucks.tabNavigator("annual_inspection")
          },
          {
            title: t("insurance policy list"),
            path: paths.dashboard.general.trucks.tabNavigator("insurance_policy")
          },
        ]
      },
      {
        title: "chassis management",
        icon: ICONS.label,
        path: paths.dashboard.general.chassis.main,
        children: [
          {
            title: t("Chassis List"),
            path: paths.dashboard.general.chassis.main
          },
          {
            title: t("license plate list"),
            path: paths.dashboard.general.chassis.tabNavigator("license")
          },
          {
            title: t("maintainance list"),
            path: paths.dashboard.general.chassis.tabNavigator("maintainance")
          },
          {
            title: t("annual inspection list"),
            path: paths.dashboard.general.chassis.tabNavigator("annual_inspection")
          },
          {
            title: t("insurance policy list"),
            path: paths.dashboard.general.chassis.tabNavigator("insurance_policy")
          },
        ]
      },
      {
        title: t("shipment master"),
        icon: ICONS.chat,
        path: paths.dashboard.general.shipmentmaster.shiplineList,
        children: [
          {
            title: t("Shipline"),
            path: paths.dashboard.general.shipmentmaster.shiplineList
          },
          {
            title: t("Commodity"),
            path: paths.dashboard.general.shipmentmaster.commodityList
          },
          {
            title: t("Container Sizes"),
            path: paths.dashboard.general.shipmentmaster.containersizes
          },
          {
            title: t("Run Types"),
            path: paths.dashboard.general.shipmentmaster.runTypes
          },
        ]
      },
      {
        title: t("dispatch management"),
        icon: ICONS.chat,
        path: paths.dashboard.general.shipment.board,
        children: [
          {
            title: t("Planning Board"),
            path: paths.dashboard.general.shipment.board
          },
          {
            title: t("shipment containers"),
            path: paths.dashboard.general.shipment.containers
          },
        ]
      },
      {
        title: t("shipment management"),
        icon: ICONS.calendar,
        path: paths.dashboard.general.shipment.list,
        children: [
          {
            title: t("shipment list"),
            path: paths.dashboard.general.shipment.list
          },
          {
            title: t("customer information"),
            path: paths.dashboard.general.shipment.tabNavigator("customer_information")
          },
          {
            title: t("shipment details"),
            path: paths.dashboard.general.shipment.tabNavigator("shipment_details")
          },
          {
            title: t("pickup service & return address"),
            path: paths.dashboard.general.shipment.tabNavigator("pickup_service_address_return")
          },
          {
            title: t("vessel details"),
            path: paths.dashboard.general.shipment.tabNavigator("vessel_details")
          },
          {
            title: t("container moves"),
            path: paths.dashboard.general.shipment.tabNavigator("container_moves")
          },
          {
            title: t("charges"),
            path: paths.dashboard.general.shipment.tabNavigator("charges")
          },
          {
            title: t("documents"),
            path: paths.dashboard.general.shipment.tabNavigator("documents")
          },
        ]
      },
      {
        title: t("Invoices"),
        icon: ICONS.invoice,
        path: paths.dashboard.general.invoice.root,
        children: [
          {
            title: t("Invoice list"),
            path: paths.dashboard.general.invoice.root,
          },
          {
            title: t("Invoice Emails"),
            path: paths.dashboard.general.invoice.emails,
          },
        ]
      }, {
        title: t("Payments"),
        icon: ICONS.banking,
        path: paths.dashboard.general.payment.driverPayout,
        children: [
          {
            title: t("Shipment Payments"),
            path: paths.dashboard.general.payment.shipmentPayment,
          },
          {
            title: t("Driver Payouts"),
            path: paths.dashboard.general.payment.driverPayout,
          },
          {
            title: t("Driver Payouts Summary"),
            path: paths.dashboard.general.payment.driverPayoutSummary,
          },
        ]
      },
      {
        title: t('Admin Management'),
        path: paths.manage.root,
        icon: ICONS.user,
        children: [
          {
            title: t('Users'),
            path: paths.manage.user.list
          },
          {
            title: t('Groups'),
            path: paths.manage.group.list
          },
          {
            title: t('Modules'),
            path: paths.manage.module.list
          },
          {
            title: t('Change Password'),
            path: paths.manage.user.change_password
          },
        ],
      },
    ],
  }], [t]);
  return data;
}