// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Label from 'src/components/label';

// ----------------------------------------------------------------------

export default function NavUpgrade() {
  const { user } = useMockedUser();

  const { t } = useLocales();

  return (
    <Stack
      sx={{
        px: 2,
        py: 5,
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center">
        <Box sx={{ position: 'relative' }}>
          <Avatar src="https://firebasestorage.googleapis.com/v0/b/hsl-terminal-dispatch.appspot.com/o/illustration_docs.png?alt=media&token=0af10cb5-12e7-466e-9fdd-ff99af2cfd26" alt="help image" sx={{ width: 48, height: 48 }} />
        </Box>

        <Stack spacing={0.5} sx={{ mt: 1.5, mb: 2 }}>
          <Typography variant="subtitle2" noWrap>
            Hi, user
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>
            Need help?
          </Typography>
          <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>
            Please email
          </Typography>
          <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>
          itservices@hcterminal.com

          </Typography>
        </Stack>

      </Stack>
    </Stack>
  );
}
