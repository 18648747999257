import { compact, flatten } from "lodash";
import { paths } from "src/routes/paths";
import { apiRequest } from "src/utils/axios";

export interface IPermission {
    view: boolean;
    add: boolean;
    edit: boolean;
    delete: boolean;
}
export interface IPagePermission extends IPermission {
    id: string;
    slug: string;
    title: string;
}

const setPermissionsData = async () => {
    try {
        const res: any = await apiRequest(`auth/permissions`, {});
        window.localStorage.setItem("permissionContext", JSON.stringify(res.data));
    } catch (error) {
        console.log(error.message);
    }
}

export const resetPagesPermissions = async () => {
    try {
        window.localStorage.removeItem("permissionContext");
        await setPermissionsData();
    } catch (error) {
        console.log(error.message);
    }
    return null;
}

export const deletePagesPermissions = () => {
    try {
        window.localStorage.removeItem("permissionContext");
    } catch (error) {
        console.log(error.message);
    }
    return null;
}
const getPagesPermissionsFromStorage = (): Array<IPagePermission> | null => {
    let result: Array<IPagePermission> | null = null;
    try {
        const storedPermission = window.localStorage.getItem("permissionContext");
        if (storedPermission !== null) {
            result = JSON.parse(storedPermission) as Array<IPagePermission>;
        }
        return result;
    } catch (error) {
        console.log(error.message);
    }
    return null;
};

export const getPagesPermissions = async () => {
    try {
        let storedPermission = getPagesPermissionsFromStorage();
        if (!storedPermission) {
            await setPermissionsData();
        }
        storedPermission = getPagesPermissionsFromStorage();

        return storedPermission;
    } catch (error) {
        console.log(error.message);
    }
    return null;
}

export const getPagePermission = (slug: string): IPermission => {
    const permission = (getPagesPermissionsFromStorage() ?? []).find(item => item.slug === slug);
    return {
        view: !!permission?.view,
        add: !!permission?.add,
        edit: !!permission?.edit,
        delete: !!permission?.delete,
    };
};

export const requirePagePermission = (slug: string, router: any) => {
    const permission = getPagePermission(slug);
    if (!permission.view) {
        router.push(paths.dashboard.permission);
    }
};

export const requireFormPermission = (slug: string, router: any, edit?: boolean) => {
    const permission = getPagePermission(slug);
    if (edit) {
        if (!permission.edit) {
            router.push(paths.dashboard.permission);
        }
    } else if (!permission.add) {
        router.push(paths.dashboard.permission);
    }
};

export const filterMenuPermissions = (firstArray: any[], permissionArray: any[]) => {
    const filteredArray = firstArray.map((section) => {
        if (section.subheader === 'Pages') {
            const filteredItems = section.items.map((item: { path: any, title: any, children: any[] }) => {
                const filteredChildren: any[] = compact(item.children.map((child: { path: string }) => {
                    const per = permissionArray.find((module: any) => module.slug === child.path);
                    return per && per.view === true ? { ...child, title: per.title } : undefined;
                }));
                return filteredChildren.length && { ...item, children: filteredChildren };
            });
            return { ...section, items: filteredItems.filter((a: any) => !!a) };
        }
        return { ...section };
    });
    return filteredArray;
};

export const filterModulesByFormCode = (navData: any[], modules: any[]) =>
    flatten(compact(navData.map((section) => {
        if (section.subheader === 'Pages') {
            const filteredItems = section.items.map((item: { path: any, title: any, children: any[] }) => {
                const filteredChildren: any[] = compact(item.children.map((child: { path: string }) => {
                    const per = modules.find((module: any) => module.slug === child.path);
                    return per && { ...child, title: per.title, id: per.id, level: per.permission_level };
                }));
                return filteredChildren.length && { ...item, children: filteredChildren };
            });
            return filteredItems.filter((a: any) => !!a);
        }
        return null;
    })));

export const getModuleRoutes = (navMenu: any[]) => flatten(navMenu.map(section =>
    section.items.map((item: any) => ({
        title: item.title,
        path: item.path,
        children: item.children.map((it: any) => ({
            title: it.title,
            path: it.path,
        }))
    }))
));


// OLD METHODS

export const GetTabs = (moduleName: string) => {
    let permissions: any = [];
    let temp: any = []
    const storedPermission = window.localStorage.getItem("permissionContext");
    if (storedPermission !== null) {
        permissions = JSON.parse(storedPermission);
    }
    permissions.forEach((item: any) => {
        if (item.module.includes(moduleName)) {
            item.children.forEach((s: any) => {
                if (s.view === 1) {
                    if (s.value) {
                        temp = [...temp, s.value]
                    }
                }
            })
        }
    });
    return temp
};