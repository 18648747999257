import axios, { AxiosProgressEvent, AxiosRequestConfig, CancelToken } from 'axios';
// config
import { HSL_BACKEND_API } from 'src/config-global';

// ----------------------------------------------------------------------
const axiosInstance = axios.create({ baseURL: HSL_BACKEND_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_API}/${url}`, { ...config });

  return res.data.data;
};

export const fetcherPost = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.post(`${process.env.REACT_APP_BACKEND_API}/${url}`, { ...config });

  return res.data.data;
};


const getAccessToken = () => sessionStorage.getItem('accessToken');

export const authRequest = (url: string, args: any = {}) => (
  new Promise((resolve, reject) => {
    axiosInstance.post(`${process.env.REACT_APP_BACKEND_BASE_API}/${url}`, args).then(res => {
      apiSuccesshandler(res, resolve, reject);
    }).catch(err => {
      reject(err);
    });
  })
);

const apiSuccesshandler = (res: any, resolve: (value: unknown) => void, reject: (value: unknown) => void) => {
  if ([200, 201].includes(res.status)) {
    if (res.data.status === 'error') {
      reject(res.data);
    } else {
      resolve(res.data);
    }
  } else {
    reject(res.data);
  }
};

export const apiRequest = (url: string, args: any = {}) => new Promise((resolve, reject) => {
  try {
    axiosInstance.post(`${process.env.REACT_APP_BACKEND_API}/${url}`, args, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`
      }
    }).then(res => {
      apiSuccesshandler(res, resolve, reject);
    }).catch(err => {
      reject(err);
    });
  } catch (error) {
    reject(error);
  }
});

export const apiFileRequest = (url: string, args: any = {}) => new Promise((resolve, reject) => {
  try {
    axiosInstance.post(`${process.env.REACT_APP_BACKEND_API}/${url}`, args, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`
      },
      responseType: 'blob' // Specify the response type as 'blob' for file downloads
    }).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    });
  } catch (error) {
    reject(error);
  }
});

export const cancelToken = axios.CancelToken;

export const apiUpload = (url: string, args: {
  body: object | string;
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
  cancelToken?: CancelToken;
}) => new Promise((resolve, reject) => {
  const { body, ...extra } = args;
  axios.post(`${process.env.REACT_APP_BACKEND_API}/${url}`, body, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'multipart/form-data',
    },
    ...(extra || {}),
  }).then(res => {
    apiSuccesshandler(res, resolve, reject);
  }).catch(err => {
    reject(err);
  });
});


export const hslEndPoints = {
  login: "login",
  company: {
    list: "companies",
  },
  yard: {
    list: "yards",
    create: "yards/store",
    update: "yards/update"
  },
  user: {
    list: "users",
    create: "users/store",
    update: "users/update",
    change_password: "users/change_password",
  },
  yardLocation: {
    list: "yard_locations"
  },
  driver: {
    list: "drivers",
    create: "drivers/store",
    update: "drivers/update",
    show: "drivers/show",
    status: "drivers/status",
    tabs: {
      disability_insurances: {
        list: "drivers/disability_insurances",
        create: "drivers/disability_insurances/store",
        update: "drivers/disability_insurances/update",
      },
      license: {
        list: "drivers/licenses",
        create: "drivers/licenses/store",
        update: "drivers/licenses/update",
      },
      cvors: {
        list: "drivers/cvors",
        create: "drivers/cvors/store",
        update: "drivers/cvors/update",
      },
    },
  },
  client: {
    list: "clients",
    show: "clients/show",
    create: "clients/store",
    update: "clients/update",
    tabs: {
      contact: {
        list: "clients/contacts",
        show: "clients/contacts/show",
        create: "clients/contacts/store",
        update: "clients/contacts/update",
      },
    },
  },
  trucks: {
    list: "trucks",
    create: "trucks/store",
    update: "trucks/update",
    status: "trucks/status",
    tabs: {
      license_plate: {
        list: "trucks/license_plate_renewals",
        create: "trucks/license_plate_renewals/store",
        update: "trucks/license_plate_renewals/update",
      },
      preventive_maintenances: {
        list: "trucks/preventive_maintenances",
        create: "trucks/preventive_maintenances/store",
        update: "trucks/preventive_maintenances/update",
      },
      annual_inspections: {
        list: "trucks/annual_inspections",
        create: "trucks/annual_inspections/store",
        update: "trucks/annual_inspections/update",
      },
      insurances: {
        list: "trucks/insurances",
        create: "trucks/insurances/store",
        update: "trucks/insurances/update",
      },
    },
  },
  chassis: {
    list: "chassis",
    create: "chassis/store",
    update: "chassis/update",
    status: "chassis/status",
    tabs: {
      license_plate: {
        list: "chassis/license_plate_renewals",
        create: "chassis/license_plate_renewals/store",
        update: "chassis/license_plate_renewals/update",
      },
      preventive_maintenances: {
        list: "chassis/preventive_maintenances",
        create: "chassis/preventive_maintenances/store",
        update: "chassis/preventive_maintenances/update",
      },
      annual_inspections: {
        list: "chassis/annual_inspections",
        create: "chassis/annual_inspections/store",
        update: "chassis/annual_inspections/update",
      },
      insurances: {
        list: "chassis/insurances",
        create: "chassis/insurances/store",
        update: "chassis/insurances/update",
      },
    },
  },
  shipment_status: {
    list: "shipment_status",
    store: "shipment_status/store",
    show: "shipment_status/show",
    update: "shipment_status/update",
    update_ordered: "shipment_status/update_ordered",
    delete: "shipment_status/destroy",
  },
  shipment: {
    list: "shipments",
    status: "shipments/update_shipment_status",
    container_status: "shipments/containers/update_container_status",
    show: "shipments/show",
    store: "shipments/store",
    update: "shipments/update",
    export_excel: "shipments/export_excel",
    update_shipment_info: "shipments/update_shipment_info",
    update_pickups_returns: "shipments/containers/update_pickups_returns",
    update_vessel_details: "shipments/update_vessel_details",
    update_container_moves: "shipments/update_container_moves",
    update_container_runs: "shipments/update_container_runs",
    update_container_changes: "shipments/update_container_charges",
    container_runs_payment_list: "shipments/container_runs_payment_list",
    update_container_runs_payments: "shipments/update_container_runs_payments",
    update_notes: "shipments/containers/update_notes",
    board: "shipments/board",
    update_task: "shipments/board/update_task",
    update_container_appointment_datetime: "shipments/board/update_container_appointment_datetime",
    get_previous_runs: "shipments/board/get_previous_runs",
    pending_invoice_list: "shipments/pending-invoices",
    cn_shipment_tracking: "cn_shipment_tracking",
    cp_shipment_tracking: "cp_shipment_tracking",
    containers: {
        list: "shipments/containers",
        moves:{
            driver_pay_approval: "shipment/containers/move/driver_pay_approval",
        },
        driver_payment_rate_confirmation_export: "shipments/containers/driver_payment_rate_confirmation_export",
        driver_payment_rate_confirmation_list: "shipments/containers/driver_payment_rate_confirmation_list",
        driver_payment_rate_confirmation_email: "shipments/containers/driver_payment_rate_confirmation_email"
    },
  },
  
  shiplinemaster: {
    shipline: {
      list: "shiplines",
      store: "shiplines/store",
      update: "shiplines/update"
    },
    commodity: {
      list: "commodities",
      store: "commodities/store",
      update: "commodities/update",
    },
    container_size: {
      list: "container_sizes",
      store: "container_sizes/store",
      update: "container_sizes/update"
    },
    run_types: {
      list: "run_types",
      store: "run_types/store",
      update: "run_types/update"
    }
  },
  billing: {
    invoice: {
      list: "invoices",
      store: "invoices/store",
      update: "invoices/update",
      status: "invoices/status",
      download: "invoices/download",
      download_docs: "invoices/download_docs",
      attachments: "invoices/attachments",
      send_email: "invoices/send_email",
      email_list: "invoices/emails"
    },
    payouts: {
      list: "payouts",
      store: "payouts/store",
      show: "payouts/show",
      update: "payouts/update",
      status: "payouts/status",
      generate: "payouts/generate/payroll",
      driver_payouts: {
        summary: "payouts/summary",
        download_pdf: "payouts/driver/download_pdf",
        download_excel: "payouts/driver/download_excel",
        other_payment_list: "payouts/other_payment_list",
        create_update_other_payments: "payouts/create_update_other_payments",
        list: "payouts/list",
        // detail: "driver_payouts/detail",
        // store: "driver_payouts/store",
        // update: "driver_payouts/update",
      }
    },
    driver_payouts: {
      list: "driver_payouts",
      summary: "driver_payouts/summary",
      detail: "driver_payouts/detail",
      store: "driver_payouts/store",
      update: "driver_payouts/update",
    }
  }
}

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: 'auth/me',
    login: 'auth/login',
    register: 'auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};

