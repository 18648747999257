// @mui
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
// theme
import { bgBlur } from 'src/theme/css';
//
import Iconify from '../iconify';

// ----------------------------------------------------------------------

type Props = {
  onDownload?: VoidFunction;
};

export default function DownloadButton({ onDownload }: Props) {
  const theme = useTheme();

  return (
    <IconButton
      onClick={onDownload}
      sx={{
        p: 0,
        bottom: 0,
        right: 24,
        width: 24,
        height: 24,
        zIndex: 9,
        opacity: 1,
        position: 'absolute',
        // borderRadius: 'unset',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'grey.800',
        color: 'common.white',
      }}
    >
      <Iconify icon="eva:arrow-circle-down-fill" width={24} />
    </IconButton>
  );
}
